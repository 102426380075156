(function ($) {
    'use strict';

    $(document).ready(function () {
        // Your JS & jQuery Code here
        let formCotizacion = document.getElementById('formCotizacion');
        if (formCotizacion) {
            formCotizacion.reset();

            function validar() {
                let validarConfig = {
                    items: {
                        'cf_name-pop-up': {
                            expresion: 'nombreEspecial',
                            select: false,
                            label: 'namelabel',
                            texto: 'El nombre es obligatorio, solo puede contener numeros, letras, guion y guion bajo.',
                        },
                        'cf_lastname-pop-up': {
                            expresion: 'nombreEspecial',
                            select: false,
                            label: 'apellidoLabel',
                            texto: 'El apellid es obligatorio, solo puede contener numeros, letras, guion y guion bajo.',
                        },
                        'cf_email-pop-up': {
                            expresion: 'correo',
                            select: false,
                            label: 'emailLabel',
                            texto: 'El correo es obligatorio.',
                        },
                        'cf_tel-pop-up': {
                            expresion: 'telefono',
                            select: false,
                            label: 'telLabel',
                            texto: 'El número de contacto es obligatorio.',
                        },
                        'cf_subject-pop-up': {
                            expresion: 'nombreEspecial',
                            select: false,
                            label: 'asuntoLabel',
                            texto: 'El asunto es obligatorio, solo puede contener numeros, letras, guion y guion bajo.',
                        },
                        'cf_message-pop-up': {
                            expresion: 'texto',
                            select: false,
                            label: 'mensajeLabel',
                            opcional: true,
                            texto: 'Máximo 500 caracteres, alfanumerio, punto y coma',
                        },
                    },
                    idMensaje: 'formulario__mensaje',
                    idBoton: 'btnSubmit',
                };
                let validarFormulario = new ValidadorForms(validarConfig);

                validarFormulario.iniciar();
            }

            validar();

            formCotizacion.onsubmit = async function (e) {
                e.preventDefault();
                grecaptcha.ready(function () {
                    grecaptcha
                        .execute(claveRecaptcha, { action: 'formularioPop' })
                        .then(function (respuesta_token) {
                            const itokenRecaptcha =
                                document.getElementById('tokenRecaptcha');
                            itokenRecaptcha.value = respuesta_token;

                            let nombre =
                                document.getElementById('cf_name-pop-up');
                            let apellido =
                                document.getElementById('cf_lastname-pop-up');
                            let email =
                                document.getElementById('cf_email-pop-up');
                            let tel = document.getElementById('cf_tel-pop-up');
                            let asunto =
                                document.getElementById('cf_subject-pop-up');
                            let mensaje =
                                document.getElementById('cf_message-pop-up');
                            enviar(
                                nombre.value,
                                apellido.value,
                                email.value,
                                tel.value,
                                asunto.value,
                                mensaje.value,
                                itokenRecaptcha.value
                            );
                            formCotizacion.reset();
                            // selecciona el botón para cerrar el modal
                            const button = document.getElementById('btnClose');
                            // simula un clic en el botón
                            button.click();
                        });
                });
            };
        }

        let formContacto = document.getElementById('formContacto');
        if (formContacto) {
            formContacto.reset();

            function validar() {
                let validarConfig = {
                    items: {
                        cf_name: {
                            expresion: 'nombreEspecial',
                            select: false,
                            label: 'namelabelC',
                            texto: 'El nombre es obligatorio, solo puede contener numeros, letras, guion y guion bajo.',
                        },
                        cf_lastname: {
                            expresion: 'nombreEspecial',
                            select: false,
                            label: 'apellidoLabelC',
                            texto: 'El apellid es obligatorio, solo puede contener numeros, letras, guion y guion bajo.',
                        },
                        cf_email: {
                            expresion: 'correo',
                            select: false,
                            label: 'emailLabelC',
                            texto: 'El correo es obligatorio.',
                        },
                        cf_tel: {
                            expresion: 'telefono',
                            select: false,
                            label: 'telLabelC',
                            texto: 'El número de contacto es obligatorio.',
                        },
                        cf_subject: {
                            expresion: 'nombreEspecial',
                            select: false,
                            label: 'asuntoLabelC',
                            texto: 'El asunto es obligatorio, solo puede contener numeros, letras, guion y guion bajo.',
                        },
                        cf_message: {
                            expresion: 'texto',
                            select: false,
                            label: 'mensajeLabelC',
                            opcional: true,
                            texto: 'Máximo 500 caracteres, alfanumerio, punto y coma',
                        },
                    },
                    idMensaje: 'formulario__mensajeC',
                    idBoton: 'btnSubmitC',
                };
                let validarFormulario = new ValidadorForms(validarConfig);

                validarFormulario.iniciar();
            }

            validar();

            formContacto.onsubmit = function (e) {
                e.preventDefault();
                grecaptcha.ready(function () {
                    grecaptcha
                        .execute(claveRecaptcha, {
                            action: 'formularioContacto',
                        })
                        .then(function (respuesta_token) {
                            const itokenRecaptcha = document.getElementById(
                                'tokenRecaptchaContacto'
                            );
                            itokenRecaptcha.value = respuesta_token;

                            let nombre = document.getElementById('cf_name');
                            let apellido =
                                document.getElementById('cf_lastname');
                            let email = document.getElementById('cf_email');
                            let tel = document.getElementById('cf_tel');
                            let asunto = document.getElementById('cf_subject');
                            let mensaje = document.getElementById('cf_message');
                            enviar(
                                nombre.value,
                                apellido.value,
                                email.value,
                                tel.value,
                                asunto.value,
                                mensaje.value,
                                itokenRecaptcha.value
                            );
                            formContacto.reset();
                        });
                });
            };
        }

        function enviar(
            nombre,
            apellido,
            email,
            tel,
            asunto,
            mensaje,
            itokenRecaptcha
        ) {
            // crea un objeto FormData para enviar los datos al archivo PHP
            const datos = new FormData();
            datos.append('nombre', nombre);
            datos.append('apellido', apellido);
            datos.append('email', email);
            datos.append('tel', tel);
            datos.append('asunto', asunto);
            datos.append('mensaje', mensaje);
            datos.append('token', itokenRecaptcha);

            // Mostrar la alerta y evitar que se cierre haciendo clic fuera de ella
            Swal.fire({
                title: 'Enviando mensaje.....',
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: () => {
                    // Evitar que se cierre haciendo clic fuera de la alerta
                    const popup = Swal.getPopup();
                    popup.classList.remove('swal2-show');
                    setTimeout(() => {
                        popup.classList.add(
                            'animate__animated',
                            'animate__headShake'
                        );
                    });
                    setTimeout(() => {
                        popup.classList.remove(
                            'animate__animated',
                            'animate__headShake'
                        );
                    }, 500);
                    return false;
                },
                didOpen: () => {
                    Swal.showLoading();
                    // Realizar la solicitud de red (fetch) y esperar a que se complete
                    const fetchData = async () => {
                        try {
                            const response = await fetch('/formulario', {
                                method: 'POST',
                                body: datos,
                            });
                            const data = await response.json();
                            // Mostrar los datos al usuario
                            // console.log(data);
                            // Permitir que el usuario cierre la alerta
                            Swal.close();
                            if (data.valido) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Mensaje enviado!!',
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: data.mensaje,
                                    // footer: '<a href="">Why do I have this issue?</a>',
                                });
                            }
                        } catch (error) {
                            console.error(error);
                            // Mostrar un mensaje de error al usuario y permitir que cierre la alerta
                            Swal.close();
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Mensaje no enviado, intentelo mas tarde',
                                // footer: '<a href="">Why do I have this issue?</a>',
                            });
                        }
                    };
                    fetchData();
                },
            });
        }
    }); // end of document ready
})(jQuery);
